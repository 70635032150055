.map-box-container {
  width: 100%;
  height: 100%;
  min-height: 400px;
  position: relative;
}

.card {
  background: #27293d;
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

.card-stats hr {
  border-color: #2b3553;
}

.card-stats .numbers {
  text-align: right;
}

.mb-3 p {
  margin-bottom: 1rem !important;
}

.horizontal-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}

.justify {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.table-responsive {
  overflow: auto;
}

.polygon-table {
  tbody {
    tr:hover {
      cursor: pointer;
    }
  }
}

.mini-canvas {
  transform: scale(1, -1);
}

.mapbox-logo {
  display: none;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapbox-improve-map {
  display: none;
}
.mapboxgl-ctrl-compass {
  display: none;
}

.mapboxgl-ctrl-fullscreen {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yNCAxNnY1LjVjMCAxLjc1LS43NSAyLjUtMi41IDIuNUgxNnYtMWwzLTEuNS00LTUuNSAxLTEgNS41IDQgMS41LTNoMXpNNiAxNmwxLjUgMyA1LjUtNCAxIDEtNCA1LjUgMyAxLjV2MUg3LjVDNS43NSAyNCA1IDIzLjI1IDUgMjEuNVYxNmgxem03LTExdjFsLTMgMS41IDQgNS41LTEgMS01LjUtNEw2IDEzSDVWNy41QzUgNS43NSA1Ljc1IDUgNy41IDVIMTN6bTExIDIuNWMwLTEuNzUtLjc1LTIuNS0yLjUtMi41SDE2djFsMyAxLjUtNCA1LjUgMSAxIDUuNS00IDEuNSAzaDFWNy41eiIvPgo8L3N2Zz4K);
}

.mapboxgl-ctrl-resize {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJleHBhbmQtYWx0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtZXhwYW5kLWFsdCBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTIxMi42ODYgMzE1LjMxNEwxMjAgNDA4bDMyLjkyMiAzMS4wMjljMTUuMTIgMTUuMTIgNC40MTIgNDAuOTcxLTE2Ljk3IDQwLjk3MWgtMTEyQzEwLjY5NyA0ODAgMCA0NjkuMjU1IDAgNDU2VjM0NGMwLTIxLjM4MiAyNS44MDMtMzIuMDkgNDAuOTIyLTE2Ljk3MUw3MiAzNjBsOTIuNjg2LTkyLjY4NmM2LjI0OC02LjI0OCAxNi4zNzktNi4yNDggMjIuNjI3IDBsMjUuMzczIDI1LjM3M2M2LjI0OSA2LjI0OCA2LjI0OSAxNi4zNzggMCAyMi42Mjd6bTIyLjYyOC0xMTguNjI4TDMyOCAxMDRsLTMyLjkyMi0zMS4wMjlDMjc5Ljk1OCA1Ny44NTEgMjkwLjY2NiAzMiAzMTIuMDQ4IDMyaDExMkM0MzcuMzAzIDMyIDQ0OCA0Mi43NDUgNDQ4IDU2djExMmMwIDIxLjM4Mi0yNS44MDMgMzIuMDktNDAuOTIyIDE2Ljk3MUwzNzYgMTUybC05Mi42ODYgOTIuNjg2Yy02LjI0OCA2LjI0OC0xNi4zNzkgNi4yNDgtMjIuNjI3IDBsLTI1LjM3My0yNS4zNzNjLTYuMjQ5LTYuMjQ4LTYuMjQ5LTE2LjM3OCAwLTIyLjYyN3oiPjwvcGF0aD48L3N2Zz4=);
  background-size: 19px;
  background-position: center;
  background-repeat: no-repeat;
}
.small-card {
  height: 400px;
  overflow: auto;
  position: relative;
}

.clickable-table-row {
  cursor: pointer;
}

.highlight-background {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.btn.btn-github.btn-simple {
  color: #ccc;
  border-color: #ccc;
}

.btn.btn-github.btn-simple.active {
  background-color: #ccc !important;
  border-color: #ccc !important;
  color: #1e1e24 !important;
  background-image: none !important;
}

.btn.btn-github {
  color: #fff;
  background-size: 210% 210%;
  background-position: 100% 0;
  background-repeat: space;
}

.dropdown {
  width: inherit;
}

.dropdown-toggle {
  width: inherit;
  display: flex;
  justify-content: space-between;
  background: #27293d;
  background-color: #27293d;
  background-image: #27293d;
}

.dropdown-menu .dropdown-item {
  font-size: 1.2rem;
  color: $darker;
  font-weight: 600;
}
.row-table {
  margin-top: -19px;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #72bf24;
}

.rdtPicker td.rdtDay:not(td.rdtOld):not(td.rdtNew) {
  color: #292828c7;
  border-radius: 50%;
  line-height: 33px;
}

td.rdtMonth,
td.rdtYear {
  color: #292828c7;
}

.satellite-images-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border-radius: 0;
}

.satellite-image {
  width: 120px;
}

.satellite-images-container .satellite-images-list {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}

.satellite-images-container .satellite-images-list .satellite-arrow-button.left {
  padding-right: 5px;
}

.satellite-images-container .satellite-images-list .satellite-arrow-button.right {
  padding-left: 5px;
}

.satellite-images-container .satellite-images-list .satellite-arrow-button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  color: hsla(0, 0%, 100%, 0.2);
}

.satellite-images-container .satellite-images-list .satellite-arrow-button :hover {
  color: #fff;
}

.satellite-images-container .satellite-images-list .calendar-icon {
  position: relative;
  cursor: pointer;
  padding: 10px;
  font-size: 25px;
  border-radius: 5px;
}

.satellite-images-container .satellite-images-list .satellite-image-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  overflow-x: auto;
}

.satellite-images-container .satellite-images-list .satellite-image-list .active,
.satellite-images-container .satellite-images-list .satellite-image-list .satellite-image:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-right-color: transparent;
}

.satellite-images-container .satellite-images-list .satellite-image-list .satellite-image {
  cursor: pointer;
  background: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  border: none;
  border-right: 1px solid hsla(0, 0%, 100%, 0.1);
}

.satellite-icon {
  margin-right: 2px;
  width: 15px;
  height: 10px;
  font-size: 0.65rem;
}

.satellite-text {
  font-size: 0.65rem;
  margin-right: 5px;
  text-transform: none !important;
}

.satellite-images-container .satellite-images-list .satellite-image-list .active,
.satellite-images-container .satellite-images-list .satellite-image-list .satellite-image:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-right-color: transparent;
}

.satellite-calendar .rdtPicker {
  margin-top: 26px;
}

.satellite-calendar .rdtPicker .rdtDays td[class="rdtDay"] {
  background-color: #eee;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  border-radius: 50%;
}

.spinner {
  margin-top: 100px;
  .col-12 {
    display: grid;
    justify-content: center;
    color: $primary;
    .row {
      display: flex;
      justify-content: center;
    }
  }
}

nav {
  margin-top: -24px;
  justify-content: center;
  font-family: inherit;
  background: linear-gradient(#1e1e2f, #1e1e24);
  ul {
    margin-top: 16px;
    line-height: 32px;
    .page-link {
      font-size: 24px;
      line-height: inherit !important;
    }
  }
}
